import React from 'react';
import { Link, graphql } from 'gatsby';
import { contentArea, postList } from '../constants';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const ProjectsPage = ({ data }) => {
  const { edges: posts } = data.allMdx;
  return (
    <Layout>
      <Seo title="Projects" />
      <div css={contentArea}>
        <h1>Projects</h1>
        <ul css={postList}>
          {posts.map(({ node: page }) => (
            <li key={page.id}>
              <Link to={page.frontmatter.path}>{page.frontmatter.title}</Link>
              <p>{page.frontmatter.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default ProjectsPage;

export const pageQuery = graphql`
  query projectIndex {
    allMdx(
      sort: { fields: [frontmatter___title] }
      filter: {
        fields: { collection: { eq: "projects" } }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          ...MdxContent
        }
      }
    }
  }
`;
